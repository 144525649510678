import React from 'react';
import { ClientContext } from '@thd-nucleus/app-render/client';
import B2BTheme from '@thd-olt-global/b2b-theme';
import { NewRelicBridge } from '@thd-olt-component-react/new-relic-bridge';
import { ThdAnalytics } from '@thd-olt-component-react/thd-analytics';
import { whyDidYouRender } from '../wdyr';
import { App } from './App';
import { getRoutes } from './routes';

ThdAnalytics.init();
NewRelicBridge.init();
whyDidYouRender.init();

// const script1 = document.createElement('script');
// script1.src = '//weeklycirculars.homedepot.com/hosted_services/js/2.1.1/wishabi.js';
// const script2 = document.createElement('script');
// script2.src = '//weeklycirculars.homedepot.com/hosted_services/iframe.js';
// document.head.appendChild(script1);
// document.head.appendChild(script2);

document.addEventListener('DOMContentLoaded', async () => {
  const client = new ClientContext({
    opts: {
      federation: true
    }
  });
  client.setExperienceRoutes(getRoutes());
  if (client.customer.type === 'b2b') {
    client.setTheme({ name: 'b2b', template: B2BTheme });
  }
  client.render(<App />);
});
